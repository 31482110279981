import superjson from 'superjson';
import * as Sentry from '@sentry/nextjs';
import { getUrlWithParamsAndQueries } from 'common/utils/getUrlWithParamsAndQueries';
import { parsePathAndMethod } from 'common/utils/typedApi';

import type { APIv1RestaurantWeb } from 'src/shared-interface/ApiRestaurantWebInterface';

const defaultApiUrl = process.env.NEXT_PUBLIC_API_URL !== undefined
    ? process.env.NEXT_PUBLIC_API_URL
    : 'https://sandbox.qerko.com';

export const fetchApi = async <
    Path extends keyof APIv1RestaurantWeb['ENDPOINT'],
>(
    path: Path,
    data: APIv1RestaurantWeb['ENDPOINT'][Path]['input'],
    options: {
        signal?: AbortSignal;
        headers?: HeadersInit;
    } = {},
): Promise<
APIv1RestaurantWeb['ENDPOINT'][Path]['result'] & { result: unknown; httpStatus: number }
> => {
    const { method, path: finalPath } = parsePathAndMethod(path);

    const resolvedPath = getUrlWithParamsAndQueries(
        finalPath,
        data,
    );

    const scope = Sentry.getCurrentScope();
    const fullPath = `${defaultApiUrl}/api/v1/restaurant-web${resolvedPath}`;
    const headers = {
        'Content-Type': 'application/json',
        'X-Qerko-Superjson': '1',
        ...options.headers,
    };
    scope.setContext('API Request', {
        path,
        fullPath,
        query: 'query' in data ? data.query : null,
        params: 'params' in data ? data.params : null,
        data: 'data' in data ? JSON.stringify(data.data) : null,
        headers,
    });

    const response = await fetch(fullPath, {
        ...options,
        body: 'data' in data ? superjson.stringify(data.data) : undefined,
        headers,
        method,
        next: {
            // Important - Vercel data cache time based invalidation setting
            // https://nextjs.org/docs/app/building-your-application/caching#revalidating-1
            revalidate: 60 * 5, // 5 minutes
        },
    });

    const responseText = await response.text();

    scope.setContext('API Response', {
        statusCode: response.status,
        responseData: responseText,
    });

    if (response.status < 200 || response.status > 299) {
        throw new Error('Unexpected http status');
    }

    const json = JSON.parse(responseText);
    const responseData = superjson.deserialize(json);

    scope.setContext('API Request', null);
    scope.setContext('API Response', null);

    return {
        result: responseData,
        httpStatus: response.status,
    } as APIv1RestaurantWeb['ENDPOINT'][Path]['result'] & { result: unknown; httpStatus: number };
};

const generateCacheKey = ({ method, path, query, params, context }: {
    method: string;
    path: string;
    context?: Record<string, string | undefined> | undefined;
    query?: Record<string, string | undefined> | undefined;
    params?: Record<string, string | undefined> | undefined;
}) => ([
    `${method}:${path}`,
    {
        ...(query !== undefined ? Object.fromEntries(Object.entries(query).map(([key, value]) => ([ `query-${key}`, Array.isArray(value) ? JSON.stringify(value) : value ]))) : {}),
        ...(params !== undefined ? Object.fromEntries(Object.entries(params).map(([key, value]) => ([ `param-${key}`, value ]))) : {}),
        ...(context !== undefined ? Object.fromEntries(Object.entries(context).map(([key, value]) => ([ `context-${key}`, value ]))) : {}),
    },
]);

export const prepareApiCall = <
    Path extends keyof APIv1RestaurantWeb['ENDPOINT'],
>(
    path: Path,
    data: APIv1RestaurantWeb['ENDPOINT'][Path]['input'],
    options: {
        signal?: AbortSignal;
        headers?: HeadersInit;
    } = {},
) => {
    return {
        queryFn: (props: { signal?: AbortSignal }) => fetchApi(path, data, {
            signal: props.signal ?? options.signal,
            ...options,
        }),
        queryKey: generateCacheKey({
            path,
            // @ts-expect-error
            params: 'params' in data ? data.params : undefined,
            query: 'query' in data ? data.query : undefined,
        }),
    };
};
